<template>
  <div>
  <v-card flat class="pa-4 ma-4">
      <v-card-title>
        <v-flex sm6 offset-sm3 xs12>
          <h3
            class="headline mb-0"
            style="
              text-align: center;
              font-size: 16px;
              font-family: Tahoma, Geneva, sans-serif;
              white-space: nowrap;
            "
          >
            General Information For All Hunts
          </h3>
        </v-flex>
      </v-card-title>
      <v-card-text
        sm10
        offset-sm1
        xs12
        style="
          color: black;
          font-family: Tahoma, Geneva, sans-serif;
          font-size: 16px;
        "
      >
        <div style="color: black">
          <p>
            <strong>Hunts include:</strong> Lodging – We have a base camp with
            bunkhouse-style accommodations. It is not a fancy 5-star lodge, but
            suitable for a hunting camp! *See more below! Meals and beverages –
            Three meals and basic beverages are provided. Transportation to and
            from Rapid City Regional Airport – please just be in contact with us
            if you plan to fly so we can accommodate flight times. Full or
            Semi-guided hunting - depending on the hunt booked. All of our rifle
            deer hunts are fully guided – no exceptions! Alcoholic beverages
            within reason - if you require or want something very specific, you
            may want to bring that yourself!
          </p>
          <p>
            <strong>Hunts do NOT include:</strong> Licenses – We have a lottery
            draw system. We will assist you through the application process, but
            the cost of the license is up to you! *See more below! Taxes
            required by the state of South Dakota – The current state sales tax
            rate is 6%. Taxidermy services – We have a taxidermist available if
            needed, or you are welcome to take your trophy home to your own
            taxidermist. Meat processing – We have the option of someone who can
            process meat, or facilities for you to do it yourself if wanted.
            Shipping of trophies or meat – Most guys prefer to take home with
            them, if you want to ship, we will help if possible. Gratuity for
            Guides & Staff Firearms and ammunition – These can be available at
            reasonable cost with advance notice.
          </p>
        </div>
      </v-card-text>
    </v-card>

    <v-card flat class="pa-4 ma-4">
      <v-card-title>
        <v-flex sm6 offset-sm3 xs12>
          <h3 class="headline mb-0" style="text-align: center">Non Hunters</h3>
        </v-flex>
      </v-card-title>
      <v-card-text
        sm10
        offset-sm1
        xs12
        style="
          color: black;
          font-family: Tahoma, Geneva, sans-serif;
          font-size: 16px;
        "
      >
        <div style="color: black" >
          Non-hunters are welcome, just please let us know if you plan on bringing a non-hunting companion as it may depend on our accommodation availability! Please ask for the current pricing for a non-hunting companion.
        </div>
      </v-card-text>
    </v-card>

    <v-card flat class="pa-4 ma-4">
      <v-card-title>
        <v-flex sm6 offset-sm3 xs12>
          <h3 class="headline mb-0" style="text-align: center">
            Accommodations
          </h3>
        </v-flex>
      </v-card-title>
      <v-card-text
        sm10
        offset-sm1
        xs12
        style="
          color: black;
          font-family: Tahoma, Geneva, sans-serif;
          font-size: 16px;
        "
      >
        <div style="color: black" >
          Our accommodations are not what you would see in a 5-star resort, but it is also not a tent camp in the wilderness. We have small bunkhouse style cabins (some with electric heat, some with a wood stove). The beds do have bedding, but we recommend that you bring a sleeping bag for extra warmth if you have room. Besides the bunkhouse style cabins, the upstairs of Tomilyn’s house is used for lodging. There is a detached shower house with two complete bathrooms - there are towels provided. For husband/wife couples, we usually have them stay in a spare bedroom in the main house or in a camper for privacy. We serve meals in a garage attached to our house that is just a short distance from where you will be staying. We also have a pool table that you are more than welcome to use while socializing. We do our best to meet everyone&#39;s needs for accommodations, but if you have a special need, please let us know early so we can prepare! We are in the process of updating our accommodations, but life is busy, so it may be a few years until the work is complete!
        </div>
      </v-card-text>
    </v-card>

    <v-card flat class="pa-4 ma-4">
      <v-card-title>
        <v-flex sm6 offset-sm3 xs12>
          <h3 class="headline mb-0" style="text-align: center">
            Licensing/Tags
          </h3>
        </v-flex>
      </v-card-title>
      <v-card-text
        sm10
        offset-sm1
        xs12
        style="
          color: black;
          font-family: Tahoma, Geneva, sans-serif;
          font-size: 16px;
        "
      >
        <div style="color: black">
          SD does have a lottery draw system with preference points. Preference
          points are $5 for a resident and $10 for a nonresident per drawing.
          License costs vary depending on the application and the species.
          <br />
          <ul>
            <li>
              <b>Rifle deer</b> – We have two separate drawings: West River
              Special Buck and West River Deer. West River Special Buck is a
              private land only tag and good for any unit west of the Missouri
              River except the Black Hills and reservation units. The West River
              Deer drawing is for the same deer and same season, but it can be
              used on private or public land and is unit specific. It currently
              takes around two to three years to draw the mule deer tag and
              around one to two for a whitetail tag. West River Special
              applications are generally due mid April. West River Deer
              applications are due mid June. Preference points can be purchased
              from Sept 15 through Dec 15.
            </li>
            <li>
              <b>Archery deer</b> – Archery deer tags are unlimited for hunters
              on private land. You do still have to “apply” for the license, but
              it is practically a guarantee.
            </li>
            <li>
              <b>Turkeys</b> – Turkey licenses are hard to draw on the first
              round. Most of our hunters draw in the third round or pick up
              their tags in the leftovers. Applications are generally due mid
              February and the third round is mid March. It is not a guarantee,
              but we have never had a turkey hunter not get a license.
              Preference points can be purchased in years prior up to Dec 15.
            </li>
            <li>
              <b>Antelope</b> – Antelope licenses are hard to draw. Similar to
              the rifle deer, there is a Special Antelope drawing for private
              land only tags. It usually tags a point or two to draw antelope.
              Timelines are the same as deer for applications.
            </li>
          </ul>
        </div>
      </v-card-text>
    </v-card>

    <v-card flat class="pa-4 ma-4">
      <v-card-title>
        <v-flex sm6 offset-sm3 xs12>
          <h3
            class="headline mb-0"
            style="text-align: center; white-space: nowrap"
          >
            FAQ
          </h3>
        </v-flex>
      </v-card-title>
      <v-card-text
        sm10
        offset-sm1
        xs12
        style="
          color: black;
          font-family: Tahoma, Geneva, sans-serif;
          font-size: 16px;
        "
      >
        <div style="color: black">
          <b>How do I book a hunt?</b>
          <br />
          To book a hunt we require a deposit upon booking. You are not booked
          until a deposit is on file! Final payment is due upon arrival or 30
          days prior to arrival. Deposits are nonrefundable. If you do not draw
          the license (and have proof of application and preference point
          purchases) we will refund your deposit or roll you forward to the next
          available year. To book your hunt, contact Tomilyn at 605-441-1685!
          <br /><br />
          <b>What kind of success can I expect?</b>
          <br />
          Unless you are looking for a B&amp;C trophy you can be assured of
          several chances at nice trophy animals. The rest is up to you. We
          generally have a 98-100% success rate on rifle deer, around 50%
          success on archery deer, 150-200% success on turkeys, and have never
          had an antelope hunter go home eating tag soup. <br /><br />
          <b>What&#39;s the best firearm to bring?</b>
          <br />
          Big deer (and antelope) can be tough, we recommend a .270 or larger
          rifle, but more important is accuracy. Practice with your gun and know
          exactly where it is shooting. Practice, practice, practice. Obviously,
          on turkey hunts, most hunters already have a favorite shotgun, and on
          archery hunts, we hope you plan on bringing a bow.
          <br /><br />
          <b>How do I get a license?</b>
          <br />
          We will send information on how to apply and deadlines. You will need
          to acquire preference points for multiple years before the year you
          plan to hunt. We help you through this process. Please pay attention
          to the information we send you as if you don’t get the proper points
          it can deter a hunt you’ve had booked for years. We do not have
          transferable landowner tags, so if you do not draw the proper tag
          through the SDGF&amp;P, you are out of luck.
          <br /><br />
          <b>What is your guarantee? </b>
          <br />
          We guarantee 100% effort in assisting you to a trophy animal you will
          be proud of. We can&#39;t control weather, marksmanship, or luck.
        </div>
      </v-card-text>
    </v-card>
</div>
</template>

<script>
export default {
  mounted() {
    // Add FAQ structured data
    const scriptTag = document.createElement("script");
    scriptTag.type = "application/ld+json";
    scriptTag.textContent = JSON.stringify({
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [
        {
          "@type": "Question",
          "name": "How do I book a hunt?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "To book a hunt, we require a deposit upon booking..."
          }
        },
        {
          "@type": "Question",
          "name": "What kind of success can I expect?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Unless you are looking for a B&amp;C trophy..."
          }
        },
        // Add more questions and answers as needed
      ]
    });

    document.head.appendChild(scriptTag);
  },
}
</script>

<style>

</style>